import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheck } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Студія SprintShine
			</title>
			<meta name={"description"} content={"Місце, звідки твій автомобіль завжди їде щасливішим!"} />
			<meta property={"og:title"} content={"Головна | Студія SprintShine"} />
			<meta property={"og:description"} content={"Місце, звідки твій автомобіль завжди їде щасливішим!"} />
			<meta property={"og:image"} content={"https://velvanobiz.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://velvanobiz.com/img/15_104863.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://velvanobiz.com/img/15_104863.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://velvanobiz.com/img/15_104863.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://velvanobiz.com/img/15_104863.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://velvanobiz.com/img/15_104863.png"} />
			<meta name={"msapplication-TileImage"} content={"https://velvanobiz.com/img/15_104863.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="50px 0 50px 0" sm-padding="80px 0 50px 0" quarkly-title="Hero-11" sm-text-align="center">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-align-items="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					padding="80px 0px 80px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					sm-padding="0 0px 50px 0px"
				>
					<Text margin="0px 0px 30px 0px" font="normal 700 42px/1.2 --fontFamily-sans" sm-font="normal 700 36px/1.2 --fontFamily-sans" color="--darkL2">
						Студія SprintShine
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
					У студії SprintShine ми раді запропонувати вам першокласні послуги з миття та догляду за автомобілем. Наша дружня команда присвячує себе наданню персоналізованого сервісу, який піклується про ваш автомобіль так, ніби він наш власний. Заходьте, розслабтеся, а решту залиште нам - ваш автомобіль у надійних руках!
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						flex-direction="column"
					>
						<Button
							padding="15px 38px 15px 38px"
							background="--color-primary"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							color="--dark"
							letter-spacing="1px"
							margin="0px 0px 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--primary"
							hover-color="--darkL1"
							hover-background="rgba(215, 26, 89, 0.03)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Зв'яжіться з нами
						</Button>
					</Box>
				</Box>
				<Image
					src="https://velvanobiz.com/img/1.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					sm-height="500px"
					height="100%"
					lg-width="100%"
					lg-height="auto"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://velvanobiz.com/img/2.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://velvanobiz.com/img/3.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					SprintShine Studio поєднує в собі новітні технології догляду за автомобілем з індивідуальним підходом вашої місцевої автомийки. Заснована з метою надання неперевершеного сервісу та бездоганної уваги до деталей, наша студія пропонує широкий спектр послуг, щоб ваш автомобіль виглядав якнайкраще. Відчуйте різницю завдяки нашому найсучаснішому обладнанню та екологічно чистим продуктам, розробленим, щоб захистити ваші інвестиції та підкреслити справжній блиск вашого автомобіля.
				</Text>
			</Box>
		</Section>
		<Section
			padding="50px 0 140px 0"
			sm-padding="60px 0 60px 0"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Advantages/Features-27"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box
				border-radius="24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 40px 0px"
				width="55%"
				md-width="80%"
				sm-width="100%"
			>
				<Text
					md-text-align="center"
					margin="0px 0px 16px 0px"
					font="--base"
					color="--primary"
					letter-spacing="1px"
					text-transform="uppercase"
				>
					Чому варто обрати 
				</Text>
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					SprintShine?
				</Text>
			</Box>
			<Box
				lg-flex-wrap="wrap"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="36px"
				sm-grid-template-columns="repeat(1, 1fr)"
				sm-grid-gap="8px"
				align-items="center"
			>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaCheck}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Якість, яку можна побачити: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Завдяки високоякісним продуктам і ретельним методикам, результати говорять самі за себе.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaCheck}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Екологічно чисті методи: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Ми використовуємо водоефективні пральні машини та нетоксичні миючі засоби, щоб захистити навколишнє середовище.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Icon
						category="fa"
						icon={FaCheck}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--primary"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Доступна розкіш:
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						преміум-сервіс без преміум-цінника. Відчуйте розкішний догляд за автомобілем, доступний кожному.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});